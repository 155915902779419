import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  products: any;

  clicked=false;

  constructor(
    private http: HttpClient,
    public router: Router) { }

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.http.get("assets/data/productData.json").subscribe(res => {
      this.products = res;
    });
  }

}
