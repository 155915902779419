import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { map, filter, mergeMap } from 'rxjs/operators';
import { SEOService } from './_services/seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'bankoyoWebUI';

  constructor(
    public router: Router,
    private seoService: SEOService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.seoService.updateTitle(event['title']);
        this.seoService.updateDescription(event['description']);

        this.seoService.updateOgUrl(window.location.href);
        this.seoService.updateOgTitle(event['title']);
        this.seoService.updateOgDescription(event['description']);
        this.seoService.updateOgImage(event['imageUrl']);
        this.seoService.updateOgType('website');

        this.seoService.updateTwitterCard('bankoyo');
        this.seoService.updateTwitterDomain(window.location.host);
        this.seoService.updateTwitterUrl(window.location.href);
        this.seoService.updateTwitterTitle(event['title']);
        this.seoService.updateTwitterDescription(event['description']);
        this.seoService.updateTwitterImage(event['imageUrl']);
      });
  }

}
