import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainSiteComponent } from './layout/main-site/main-site.component';

const routes: Routes = [
  {
    path: '',
    component: MainSiteComponent,
    children: [
      {
        path: '',
        loadChildren: './layout/components/site-pages/site-pages.module#SitePagesModule'
      }
    ]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: ''
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 0]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
