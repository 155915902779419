import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../../environments/environment";
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  products: any;
  technologies: any;

  productsClicked;
  servicesClicked;
  technologyClicked;
  resourcesClicked;
  companyClicked;

  constructor(private http: HttpClient, router: Router) {
    router.events.subscribe((s) => {
      if (s instanceof NavigationEnd) {
        const tree = router.parseUrl(router.url);
        if (tree.fragment) {
          const element = document.querySelector("#" + tree.fragment);
          if (element) {
            element.scrollIntoView(true);

            var headerOffset = 120;
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition =
              elementPosition + window.pageYOffset - headerOffset;

            window.scrollTo({
              top: offsetPosition,
              behavior: "smooth",
            });
          }
        }
      }
    });
  }

  ngOnInit() {
    this.getProducts();
    this.getTechnologies();
  }

  getProducts() {
    this.http.get("assets/data/productData.json").subscribe((res) => {
      this.products = res;
    });
  }

  getTechnologies() {
    this.http.get("assets/data/technologyData.json").subscribe((res) => {
      this.technologies = res;
    });
  }

  RedirectToApiDoc() {
    window.open(environment.apiDocUrl, "_blank");
  }

  RedirectToDocumentation() {
    window.open(environment.documentationUrl, "_blank");
  }
}
