import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private title: Title, private meta: Meta) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc });
  }

  updateOgUrl(ogUrl: string) {
    this.meta.updateTag({ name: 'og:url', content: ogUrl });
  }

  updateOgTitle(ogTitle: string) {
    this.meta.updateTag({ name: 'og:title', content: ogTitle });
  }

  updateOgDescription(ogDesc: string) {
    this.meta.updateTag({ name: 'og:description', content: ogDesc });
  }

  updateOgImage(ogImage: string) {
    this.meta.updateTag({ name: 'og:image', content: ogImage });
  }

  updateOgImageType() {
    this.meta.updateTag({ property: 'og:image:type', content:"image/jpg" });
    // this.meta.updateTag({ property: 'og:image:type', content:"image/jpeg" });
  }

  updateOgType(ogType: string) {
    this.meta.updateTag({ name: 'og:type', content: ogType });
  }
  updateOgSiteName() {
 
    this.meta.updateTag({ name: 'og:site_name', content: "bankoyo" });
  }

  updateTwitterCard(twitterCard: string) {
    this.meta.updateTag({ name: 'twitter:card', content: twitterCard });
  }

  updateTwitterDomain(twitterDomain: string) {
    this.meta.updateTag({ name: 'twitter:domain', content: twitterDomain });
  }

  updateTwitterUrl(twitterUrl: string) {
    this.meta.updateTag({ name: 'twitter:url', content: twitterUrl });
  }

  updateTwitterTitle(twitterTitle: string) {
    this.meta.updateTag({ name: 'twitter:title', content: twitterTitle });
  }

  updateTwitterDescription(twitterDescription: string) {
    this.meta.updateTag({ name: 'twitter:description', content: twitterDescription });
  }

  updateTwitterImage(twitterImage: string) {
    this.meta.updateTag({ name: 'twitter:image', content: twitterImage });
  }
  updateImageSize(width: any,height:any) {
    this.meta.updateTag({ name: 'og:image.width', content: width });
    this.meta.updateTag({ name: 'og:image.height', content: height });

  }
 


}
