import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';

@Component({
  selector: 'app-success-msg-modal',
  templateUrl: './success-msg-modal.component.html',
  styleUrls: ['./success-msg-modal.component.scss']
})
export class SuccessMsgModalComponent implements OnInit {

  constructor(private modalRef: NzModalRef) { }

  ngOnInit() {
  }

  closeModal() {
    this.modalRef.destroy();
  }

}
