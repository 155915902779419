import { BASE_URL, API_DOC_URL, DOCUMENTATION_URL } from "./environment.const";

export const commonEnvironment = {
  production: false,

  //communication data
  communicationUrl: BASE_URL + "v1/communication",

  //api_doc_data
  apiDocUrl: API_DOC_URL + "apidoc/client/admin/index",

  //documentation_data
  documentationUrl: DOCUMENTATION_URL + "documentation/",
};
